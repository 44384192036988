import React, { useState, useEffect } from 'react';
import moment from "moment";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";
// import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MyContainer from "../main_container";

// material UI imports
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@material-ui/core";
// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faBook,
  faTrash,
  faSyncAlt,
  faCommentDots,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

import './Test.css';
// datatable setup
import jsZip from "jszip";
import { Today } from '@material-ui/icons';
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");



//API handling components
let API_URL = `https://csm.5techg.com/api/`;


function Test(props) {


  const [sitedata, setSiteData] = useState([]);
  const [search, setNewSearch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [dateData, setDateData] = useState("");

  console.log(toDate.toDate)
  console.log("fs", sitedata)
  const FilterData = () => {

    var resultProductData = sitedata.filter(sitedata => new Date(sitedata.date_added) >= new Date(fromDate.fromDate) &&
      new Date(sitedata.date_added) <= new Date(toDate.toDate)
    );
    console.log("filter", resultProductData)
    setSiteData(resultProductData);
    // initializeDataTable(resultProductData);

  }

  // const filtered = !search
  //   ? sitedata
  //   : sitedata.filter((site) =>
  //     site.date_added.toLowerCase().includes(search.toLowerCase()),
  //   );

  // var resultProductData = sitedata.filter(a => {
  //   var date = new Date(a.ProductHits);
  //   return (date >= startDate && date <= endDate);
  // });
  // console.log(resultProductData)

  const fetchSiteDate = () => {
    let url = API_URL;
    const query = `SELECT * FROM site;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log(res.data);
        setSiteData(res.data);
        initializeDataTable(res.data);
        // this.setState({ vehiclesData: res.data });
      })
      .catch((err) => {
        console.log("site data error: ", err);
      });
  }

  const initializeDataTable = () => {
    $("#table").DataTable({
      destroy: true,
      keys: true,
      dom:
        "<'row mb-2'<'col-sm-9' B><'col-sm-3' >>" +
        "<'row mb-2'<'col-sm-9' l><'col-sm-3' f>>" +
        "<'row'<'col-sm-12' tr>>" +
        "<'row'<'col-sm-7 mt-2 mr-5 pr-4'i><'ml-5' p>>",
      "bPaginate": false,
      "bLengthChange": true,
      "bFilter": true,
      "bInfo": false,
      "bAutoWidth": true,
      language: {
        "zeroRecords": " "
      },

      buttons: ["copy", "csv", "excel", "print"],
    });
  }

  const renderVehiclesData = () => {
    const filterDate = sitedata;
    console.log("filterDate", filterDate)
    if (filterDate == null) {
      return null;
    }

    return filterDate.map((filter, index) => {
      return (
        <tr key={`site_row_${index}`}>
          <td align="center">
            <Badge variant="primary">{filter["id"]}</Badge>{" "}
          </td>
          <td align="center">{filter["owner_name"]}</td>
          <td align="center">{filter["owner_mobile"]}</td>
          <td align="center">{moment(filter.date_added).format("DD-MM-YYYY")}</td>
          <td align="center">
            <Button
              className="mx-1"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  showFeedbackModal: true,
                  id: filterDate.id,
                });
              }}
              style={{ backgroundColor: "transparent" }}
            >
              <FontAwesomeIcon
                icon={faCommentDots}
                style={{ color: "green" }}
              />
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "transparent" }}
              onClick={(e) => {
                this.setState({
                  showUpdateModal: true,
                  id: filter.id,
                  owner_name: filter.owner_name,
                  city: filter.city,
                  date_added: filter.date_added,
                });
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} style={{ color: "blue" }} />
            </Button>
            <Button
              className="mx-1"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(filter["id"]);
                }
              }}
              style={{ backgroundColor: "transparent" }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
            </Button>
          </td>
        </tr>
      );
    });
  };



  useEffect(() => {
    fetchSiteDate();
  }, [])

  return (
    <MyContainer path={["", "sites"]}>
      <>
        <div className="container-fluid border m-0 p-1">
          <h2>Filter Data Datewise</h2>
          <hr />
          <div className='row ml-4'>
            <TextField
              id="fromDate"
              label="From Date"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              className="mb-3"
              defaultValue={fromDate}
              style={{ width: "20%" }}
              onChange={(e) =>
                setFromDate({
                  fromDate: e.target.value,
                })
              }
            />
            <TextField
              id="fromDate"
              label="To Date"
              type="date"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              className="mb-3 ml-2"
              defaultValue={toDate}
              style={{ width: "20%" }}
              onChange={(e) =>
                setToDate({
                  toDate: e.target.value,
                })
              }
            />
            <Button
              className="mt-1 mr-1 mb-3 ml-2"
              color="primary"
              variant="contained"
              onClick={() => {
                FilterData();
              }}
              value="search"
            >
              Search
            </Button>

          </div>

          {/* {sitedata.map((site) => {
            return (
              <div className="data" style={{ border: '1px solid black', marginBottom: '1em', padding: '5px' }} >
                ID :- {site.id}<br /> Name :- {site.owner_name} <br /> Date :- {moment(site.date_added).format("DD-MM-YYYY")}
              </div>
            );
          })} */}

          <Row className="ml-0 mr-0">
            <Col md="12" className="p-0 m-0 measure1">
              <div>
                <table
                  id="table"
                  className="display"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr align="center">
                      <th>Id</th>
                      <th>Name</th>
                      <th>Mobile Number</th>
                      <th>Date</th>
                      <th style={{ width: "fit-content" }}>Options</th>
                    </tr>
                  </thead>
                  <tbody>{renderVehiclesData()}</tbody>
                </table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    </MyContainer>

  );
}


export default Test;

