import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MyContainer from "../main_container";

// material UI imports
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faBook,
  faTrash,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";

// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");

//API handling components
let API_URL = `https://csm.5techg.com/api/`;

export default class VehicleReading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      addEdit: true,
      showUpdateModel: false,
      value: "1",
      activePartyId: "",
      activePartyName: "",
      activePartyMobile: "",
      activePartyAddress: "",
      activePartyType: 1,
      id: "",
      vehicle_id: "",
      vehicle_name: "",
      date: "",
      start: "",
      end: "",
      driver: "",
      description: "",
      vehiclesData: null,
    };
    this.tmp = null;
  }

  fetchPartiesData() {
    let url = API_URL;
    const query = `SELECT * FROM vehicle_reading;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("vehicle data: ", res.data);
        this.setState({ vehiclesData: res.data });
      })
      .catch((err) => {
        console.log("vehicle data error: ", err);
      });
  }

  handleUpdateSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `UPDATE vehicle_reading SET start="${this.state.start}", end="${this.state.end}", driver="${this.state.driver}", description="${this.state.description}" WHERE id=${this.state.id};`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        this.props.toast.success("Party details updated successfully");
        this.fetchPartiesData();
      })
      .catch((err) => {
        console.log("error while updating party data", err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `INSERT INTO vehicle_reading(vehicle_id, start, end, driver, description) VALUES(${this.state.vehicle_id}, '${this.state.start}', '${this.state.end}', '${this.state.driver}', '${this.state.description}')`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        setTimeout(() => {
          window.location.reload(false);
          this.props.toast.success("party details added successfully");
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteRecord(id) {
    let url = API_URL;
    const query = `delete from vehicle_reading WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("deleted status data: ", res.data);
        console.log("Party deleted successfully");
        setTimeout(() => {
          window.location.reload(false);
          this.props.toast.error("Party deleted successfully");
        }, 500);
      })
      .catch((err) => {
        console.log("record delete error: ", err);
      });
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  componentDidMount() {
    this.fetchPartiesData();
  }

  componentDidUpdate() {
    this.tmp = $("#user_table").DataTable({
      destroy: true,
      keys: true,
      order: this.tmp ? this.tmp.order() : [0, "asc"],
      buttons: [
        "copy",
        "csv",
        "excel",
        {
          extend: "pdf",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
        {
          extend: "print",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
      ],
    });
  }

  renderVehiclesData = () => {
    const parties = this.state.vehiclesData;

    if (parties == null) {
      return null;
    }

    return parties.map((vehicle_reading) => {
      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{vehicle_reading["id"]}</Badge>{" "}
          </td>
          <td align="center">{vehicle_reading["vehicle_id"]}</td>
          <td align="center">{vehicle_reading["date"]}</td>
          <td align="center">{vehicle_reading["start"]}</td>
          <td align="center">{vehicle_reading["end"]}</td>
          <td align="center">{vehicle_reading["driver"]}</td>
          <td align="center">{vehicle_reading["description"]}</td>
          <td align="center">
            <Button
              color="secondary"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  showUpdateModal: true,
                  id: vehicle_reading.id,
                  vehicle_id: vehicle_reading.vehicle_id,
                  date: vehicle_reading.date,
                  start: vehicle_reading.start,
                  end: vehicle_reading.end,
                  driver: vehicle_reading.driver,
                  description: vehicle_reading.description,
                });
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} />
            </Button>
            <Button
              className="mx-1"
              color="danger"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(vehicle_reading["id"]);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  renderUpdatePartyModal() {
    return (
      <Modal
        show={this.state.showUpdateModal}
        onHide={(e) => this.setState({ showUpdateModal: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Vehicle Reading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3">
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Id"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.id}
                    disabled
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Vehicle Id"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.vehicle_id}
                    disabled
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="date"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.date}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Start"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.start}
                    onChange={(e) =>
                      this.setState({
                        start: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="End"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.end}
                    onChange={(e) =>
                      this.setState({
                        end: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="driver"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.driver}
                    onChange={(e) =>
                      this.setState({
                        driver: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Description"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.description}
                    onChange={(e) =>
                      this.setState({
                        description: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModal: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
              >
                Update
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
  render() {
    return (
      <MyContainer path={["", "vehicle_readings"]}>
        <>
          <div className="container-fluid border m-0 p-1">
            <div class="btn-group" role="group" aria-label="Basic example">
              <Button
                className="mt-1 mr-1 mb-3"
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  this.setState({ showAddModal: true });
                }}
              >
                add new Vehicle Reading
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="mt-1 mr-1 mb-3 ml-5"
                onClick={(e) => window.location.reload(false)}
              >
                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
              </Button>
            </div>

            {this.renderUpdatePartyModal()}
            <Modal
              show={this.state.showAddModal}
              onHide={(e) => this.setState({ showAddModal: false })}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Add New Vehicle Reading
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form noValidate autoComplete="off">
                  <div className="mt-3">
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          label="Vehicle Id"
                          variant="outlined"
                          className="m-2"
                          defaultValue={this.state.vehicle_id}
                          onChange={(e) =>
                            this.setState({
                              vehicle_id: e.target.value,
                            })
                          }
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          label="Start"
                          variant="outlined"
                          className="m-2"
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({
                              start: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="address"
                          label="End"
                          variant="outlined"
                          className="m-2"
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({
                              end: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          label="driver"
                          variant="outlined"
                          className="m-2"
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({
                              driver: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="address"
                          label="Description"
                          variant="outlined"
                          className="m-2"
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({
                              description: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="mt-2 mr-1">
                    <Btn1
                      style={{ float: "right" }}
                      onClick={(e) => {
                        if (this.state.vehicle_id === "") {
                          this.props.toast.error("Enter Vehicle id");
                          return;
                        }
                        this.setState({
                          showAddModal: false,
                        });
                        this.handleAddSubmit(e);
                      }}
                    >
                      Add
                    </Btn1>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            <Row className="ml-0 mr-0">
              <Col md="12" className="p-0 m-0 measure1">
                <div>
                  <table
                    id="user_table"
                    className="display"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Id</th>
                        <th>Vehicle Id</th>
                        <th>Date</th>
                        <th>Start</th>
                        <th>End</th>
                        <th>Driver</th>
                        <th>Description</th>
                        <th>Options</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderVehiclesData()}</tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </>
      </MyContainer>
    );
  }
}
