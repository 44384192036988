import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Main from "../main_container";
import Chart from "chart.js";
import { Chart6 } from "../ChartJs";

const CANVAS_STYLE = {
  width: "100%",
  height: 350,
  display: "block",
};

const Graph = ({ refresh, oldInfo }) => {
  let chart1 = useRef(null);
  let chart2 = useRef(null);
  let chart3 = useRef(null);
  let chart4 = useRef(null);
  let chart5 = useRef(null);
  let chart6 = useRef(null);
  let chart7 = useRef(null);

  const initialize = (info) => {
    let data = null;
    let options = null;

    data = {
      labels: ["converted", "not-converted"],
      datasets: [
        {
          data: info,
          backgroundColor: ["#00BFFF", "#00FF7F"],
        },
      ],
    };

    options = {
      maintainAspectRatio: false,
      responsive: true,
    };

    new Chart(chart1, {
      type: "doughnut",
      data: data,
      options: options,
    });

    data = {
      labels: ["converted", "not-converted"],
      datasets: [
        {
          data: info,
          backgroundColor: ["#00BFFF", "#00FF7F"],
        },
      ],
    };

    options = {
      maintainAspectRatio: false,
      responsive: true,
    };

    new Chart(chart2, {
      type: "pie",
      data: data,
      options: options,
    });

    data = {
      labels: ["not converted", "converted"],
      datasets: [
        {
          label: "Is Converted",
          data: [info[1], info[0], 0, Math.max(info[0], info[1]) * 1.1],
          backgroundColor: ["#00FF7F", "#00BFFF"],
          borderWidth: 1,
        },
      ],
    };

    options = {
      responsive: true,
      maintainAspectRatio: true,
      datasetFill: false,
    };

    new Chart(chart3, {
      type: "bar",
      data: data,
      options: options,
    });

    data = {
      labels: ["is converted"],
      datasets: [
        {
          label: "Not Coverted",
          backgroundColor: "#00FF7F",
          pointRadius: false,
          pointColor: "rgba(210, 214, 222, 1)",
          pointStrokeColor: "#c1c7d1",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(220,220,220,1)",
          data: [info[1]],
        },
        {
          label: "Converted",
          backgroundColor: "#00BFFF",
          pointRadius: false,
          pointColor: "#3b8bba",
          pointStrokeColor: "rgba(60,141,188,1)",
          pointHighlightFill: "#fff",
          pointHighlightStroke: "rgba(60,141,188,1)",
          data: [info[0]],
        },
      ],
    };

    options = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
    };

    new Chart(chart4, {
      type: "bar",
      data: data,
      options: options,
    });

    data = {
      labels: ["Converted", "Not Converted"],
      datasets: [
        {
          label: "Dataset 1",
          data: [info[0], info[1]],
          backgroundColor: ["#00BFFF", "#00FF7F"],
        },
      ],
    };

    options = {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: "Chart.js Polar Area Chart",
        },
      },
    };

    new Chart(chart5, {
      type: "polarArea",
      data: data,
      options: options,
    });

    data = {
      labels: ["", "Not converted", "Converted", ""],
      datasets: [
        {
          label: "Dataset",
          data: [0, info[1], info[0], 0, 48, 20, 80],
          borderColor: "rgba(0, 0, 0, 0.8)",
          backgroundColor: "#00BFFF",
          fill: true,
          steppedLine: true,
          borderWidth: 1,
        },
      ],
    };

    options = {
      maintainAspectRatio: false,
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          axis: "x",
        },
        plugins: {
          title: {
            display: true,
            text: "stepped line Chart",
          },
        },
      },
    };

    new Chart(chart6, {
      type: "line",
      data: data,
      options: options,
    });

    data = {
      labels: ["", "Not converted", "Converted", ""],
      datasets: [
        {
          label: "Dataset",
          data: [0, info[1], info[0], 0, 48, 20, 80],
          borderColor: "rgba(0,0,0,0.8)",
          backgroundColor: "#00FF7F",
          fill: true,
          borderWidth: 1,
        },
      ],
    };

    options = {
      maintainAspectRatio: false,
      options: {
        responsive: true,
        interaction: {
          intersect: false,
          axis: "x",
        },
        plugins: {
          title: {
            display: true,
            text: "stepped line Chart",
          },
        },
      },
    };

    new Chart(chart7, {
      type: "line",
      data: data,
      options: options,
    });
  };

  useEffect(() => {
    axios({
      method: "post",
      url: "https://csm.5techg.com/api/",
      data: {
        query:
          "select count(*) from site where is_converted=0 union select count(*) from site where is_converted=1",
      },
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        //console.log(res);
        let info = [res.data[1]["count(*)"], res.data[0]["count(*)"]];
        initialize(info);
        console.log(info);
        setTimeout(() => {
          refresh(info);
        }, 30000);
      })
      .catch((e) => {
        console.log("error : ", e);
        initialize([oldInfo[0], oldInfo[1]]);
        setTimeout(() => {
          refresh(oldInfo);
        }, 30000);
      });
  }, []);

  return (
    <Main path={["", "graphs"]}>
      <div className="row">
        <div className="col-md-6 connectedSortable ui-sortable">
          <div className="card card-primary">
            <div className="card-header">
              <h3 className="card-title">Donut Chart</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chart">
                <div className="chartjs-size-monitor">
                  <div className="chartjs-size-monitor-expand">
                    <div className />
                  </div>
                  <div className="chartjs-size-monitor-shrink">
                    <div className />
                  </div>
                </div>
                <canvas
                  ref={(e) => (chart1 = e)}
                  style={CANVAS_STYLE}
                  className="chartjs-render-monitor"
                />
              </div>
            </div>
          </div>
          <div className="card card-danger">
            <div className="card-header">
              <h3 className="card-title">Pie Chart</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chartjs-size-monitor">
                <div className="chartjs-size-monitor-expand">
                  <div className />
                </div>
                <div className="chartjs-size-monitor-shrink">
                  <div className />
                </div>
              </div>
              <canvas
                ref={(e) => (chart2 = e)}
                style={CANVAS_STYLE}
                className="chartjs-render-monitor"
              />
            </div>
          </div>
          <div className="card card-danger">
            <div className="card-header">
              <h3 className="card-title">Bar Chart</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chartjs-size-monitor">
                <div className="chartjs-size-monitor-expand">
                  <div className />
                </div>
                <div className="chartjs-size-monitor-shrink">
                  <div className />
                </div>
              </div>
              <canvas
                ref={(e) => (chart3 = e)}
                style={CANVAS_STYLE}
                className="chartjs-render-monitor"
              />
            </div>
          </div>
          <div className="card card-danger">
            <div className="card-header">
              <h3 className="card-title">Stacked Bar Chart</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chartjs-size-monitor">
                <div className="chartjs-size-monitor-expand">
                  <div className />
                </div>
                <div className="chartjs-size-monitor-shrink">
                  <div className />
                </div>
              </div>
              <canvas
                ref={(e) => (chart4 = e)}
                style={CANVAS_STYLE}
                className="chartjs-render-monitor"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 connectedSortable ui-sortable">
          <div className="card card-info">
            <div className="card-header">
              <h3 className="card-title">Polar Chart</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chart">
                <div className="chartjs-size-monitor">
                  <div className="chartjs-size-monitor-expand">
                    <div className />
                  </div>
                  <div className="chartjs-size-monitor-shrink">
                    <div className />
                  </div>
                </div>
                <canvas
                  ref={(e) => (chart5 = e)}
                  style={CANVAS_STYLE}
                  className="chartjs-render-monitor"
                />
              </div>
            </div>
          </div>
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Stepped Line Chart</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chart">
                <div className="chartjs-size-monitor">
                  <div className="chartjs-size-monitor-expand">
                    <div className />
                  </div>
                  <div className="chartjs-size-monitor-shrink">
                    <div className />
                  </div>
                </div>
                <canvas
                  ref={(e) => (chart6 = e)}
                  style={CANVAS_STYLE}
                  className="chartjs-render-monitor"
                />
              </div>
            </div>
          </div>
          <div className="card card-success">
            <div className="card-header">
              <h3 className="card-title">Line Chart</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-card-widget="collapse"
                >
                  <i className="fas fa-minus" />
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="chart">
                <div className="chartjs-size-monitor">
                  <div className="chartjs-size-monitor-expand">
                    <div className />
                  </div>
                  <div className="chartjs-size-monitor-shrink">
                    <div className />
                  </div>
                </div>
                <canvas
                  ref={(e) => (chart7 = e)}
                  style={CANVAS_STYLE}
                  className="chartjs-render-monitor"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Graph;
