import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MyContainer from "../main_container";

// material UI imports
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  FormControl,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faBook,
  faTrash,
  faSyncAlt,
  faCommentDots,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

import Feedback from "../feedback/Feedback";

import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";

// datatable setup
import jsZip from "jszip";
import { param } from "jquery";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");

//API handling components
let API_URL = `https://csm.5techg.com/api/`;

export default class Site extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      addEdit: true,
      showUpdateModel: false,
      showFeedback: false,
      id: "",
      owner_name: "",
      owner_mobile: "",
      engineer_name: "",
      engineer_mobile: "",
      architect_name: "",
      architect_mobile: "",
      non_technical_contractor_name: "",
      non_technical_contractor_mobile: "",
      centring_mistry_name: "",
      centring_mistry_mobile: "",
      bandhkaam_mistry_name: "",
      bandhkaam_mistry_mobile: "",
      authority: "",
      type: "",
      stage: "",
      steel_brand: "",
      cement_brand: "",
      measurement: "0.0sq.ft.-0x0",
      last_modified: "",
      is_converted: "0",
      locality: "",
      state: "",
      city: "",
      application_user_id: "3",
      vehiclesData: null,
      resultProductData: [],
      rerender: 0,
      toDate: "",
      fromDate: "",
      en: 0,
      ntcList: null, //ntc = non_technical_contractor
      engineerList: null,
      architechList: null,
      centringmistryList: null,
      bandhkaammistryList: null,
      newPartyName: null,
    };
    this.tmp = null;
  }

  getPartyList() {
    let url = API_URL;
    // const query = `SELECT CONCAT(id, ', ', name) AS name, address FROM party;`;
    let query = `SELECT id, name, address, mobile FROM party_manager WHERE type = 'Contractor';`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("id+name data: ", res.data);
        this.setState({ ntcList: res.data });
      })
      .catch((err) => {
        console.log("An error occured while fetching contractors: ", err);
      });

    query = `SELECT id, name, address, mobile FROM party_manager WHERE type = 'Engineer';`;
    data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("id+name data: ", res.data);
        this.setState({ engineerList: res.data });
      })
      .catch((err) => {
        console.log("An error occured while fetching engineers: ", err);
      });

    query = `SELECT id, name, address, mobile FROM party_manager WHERE type = 'Architect';`;
    data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("id+name data: ", res.data);
        this.setState({ architechList: res.data });
      })
      .catch((err) => {
        console.log("An error occured while fetching architects: ", err);
      });

    query = `SELECT id, name, address, mobile FROM party_manager WHERE type = 'CentringMistry';`;
    data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("id+name data: ", res.data);
        this.setState({ centringmistryList: res.data });
      })
      .catch((err) => {
        console.log("An error occured while fetching centringmistries: ", err);
      });

    query = `SELECT id, name, address, mobile FROM party_manager WHERE type = 'BandhkaamMistry';`;
    data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("id+name data: ", res.data);
        this.setState({ bandhkaammistryList: res.data });
      })
      .catch((err) => {
        console.log(
          "An error occured while fetching bandhkaam-mistries: ",
          err
        );
      });
  }
  // new fun
  // insertNewPartyAndSave = () => {
  //     const query = `INSERT INTO party_manager (name, address) values("${this.state.newPartyName}", "${this.state.address}")`;
  //     const data = { crossDomain: true, crossOrigin: true, query: query };
  //     axios
  //         .post(API_URL, data)
  //         .then((res) => {
  //             console.log("insert party successful");
  //             console.log("insert response: ", res.data.insertId);
  //             this.setState({ partyId: res.data.insertId });
  //         })
  //         .catch((err) => {
  //             console.log("failed to insert party, error: ", err);
  //         });
  // };

  FilterData(e) {
    const sites = this.state.vehiclesData;
    var resultProductData = sites.filter(
      (sites) =>
        new Date(sites.date_added) >= new Date(this.state.fromDate) &&
        new Date(sites.date_added) <= new Date(this.state.toDate)
    );
    this.renderVehiclesData(resultProductData);
  }

  fetchPartiesData() {
    let url = API_URL;
    const query = `SELECT * FROM site;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        this.setState({ vehiclesData: res.data });
      })
      .catch((err) => {
        console.log("vehicle data error: ", err);
      });
  }

  handleUpdateSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query =
      `UPDATE site SET ` +
      `owner_name="${this.state.owner_name}",` +
      `engineer_name="${this.state.engineer_name}",` +
      `architect_name="${this.state.architect_name}",` +
      `non_technical_contractor_name="${this.state.non_technical_contractor_name}",` +
      `centring_mistry_name="${this.state.centring_mistry_name}",` +
      `bandhkaam_mistry_name="${this.state.bandhkaam_mistry_name}",` +
      `owner_mobile="${this.state.owner_mobile}",` +
      `engineer_mobile="${this.state.engineer_mobile}",` +
      `architect_mobile="${this.state.architect_mobile}",` +
      `non_technical_contractor_mobile="${this.state.non_technical_contractor_mobile}",` +
      `centring_mistry_mobile="${this.state.centring_mistry_mobile}",` +
      `bandhkaam_mistry_mobile="${this.state.bandhkaam_mistry_mobile}",` +
      `authority="${this.state.authority}",` +
      `en="${this.state.en}",` +
      `type="${this.state.type}",` +
      `stage="${this.state.stage}",` +
      `steel_brand="${this.state.steel_brand}",` +
      `cement_brand="${this.state.cement_brand}",` +
      `measurement="${this.state.measurement}" ,` +
      `is_converted="${this.state.is_converted}" ,` +
      `locality="${this.state.locality}" ,` +
      `state="${this.state.state}" ,` +
      `city="${this.state.city}" ` +
      ` WHERE id=${this.state.id};`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        this.props.refresh();
        this.props.toast.success("Site updated successfully");
      })
      .catch((err) => {
        console.log("error while updating party data", err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query =
      `INSERT INTO site(` +
      `en,owner_name, owner_mobile, engineer_name, engineer_mobile,` +
      `architect_name, architect_mobile,` +
      `non_technical_contractor_name, non_technical_contractor_mobile,` +
      `centring_mistry_name, centring_mistry_mobile, bandhkaam_mistry_name, bandhkaam_mistry_mobile,` +
      `authority, type, stage, steel_brand, cement_brand,` +
      `measurement, is_converted, locality, state, city, application_user_id` +
      `) VALUES(` +
      `${this.state.en},'${this.state.owner_name}', '${this.state.owner_mobile}',` +
      `'${this.state.engineer_name}', '${this.state.engineer_mobile}',` +
      `'${this.state.architect_name}', '${this.state.architect_mobile}',` +
      `'${this.state.non_technical_contractor_name}',` +
      `'${this.state.non_technical_contractor_mobile}',` +
      `'${this.state.centring_mistry_name}', '${this.state.centring_mistry_mobile}',` +
      `'${this.state.bandhkaam_mistry_name}', '${this.state.bandhkaam_mistry_mobile}',` +
      `'${this.state.authority}', '${this.state.type}', '${this.state.stage}',` +
      `'${this.state.steel_brand}', '${this.state.cement_brand}','${this.state.measurement}',` +
      `${this.state.is_converted}, '${this.state.locality}',` +
      `'${this.state.state}', '${this.state.city}', ${this.state.application_user_id})`;

    console.log(query);
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };

    axios
      .post(url, data)
      .then((res) => {
        console.log("response: ", res.data);
        this.props.toast.success("Site added successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteRecord(id) {
    let url = API_URL;
    const query = `delete from site WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        this.props.refresh();
        this.props.toast.error("Site deleted successfully");
      })
      .catch((err) => {
        console.log("record delete error: ", err);
      });
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  componentDidMount() {
    this.fetchPartiesData();
    this.getPartyList();
  }

  componentDidUpdate() {
    this.tmp = $("#user_table").DataTable({
      destroy: true,
      keys: true,
      order: this.tmp ? this.tmp.order() : [0, "asc"],
      stateSave: true,
      buttons: [
        "copy",
        "csv",
        "excel",
        {
          extend: "pdf",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
        {
          extend: "print",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
      ],
    });
  }

  renderVehiclesData = () => {
    const sites = this.state.vehiclesData;

    if (sites == null) {
      return null;
    }

    return sites.map((site, index) => {
      return (
        <tr key={`site_row_${index}`}>
          <td align="center">
            <Badge variant="primary">{site["id"]}</Badge>{" "}
          </td>
          <td align="center">
            {site["is_converted"] === 1 ? (
              <i
                className="fa fa-check-circle"
                style={{ fontSize: 16, color: "green" }}
              />
            ) : (
              <i
                className="fa fa-times-circle"
                style={{ fontSize: 16, color: "red" }}
              />
            )}
          </td>
          <td align="center">{site["owner_name"]}</td>
          <td align="center">{site["engineer_name"]}</td>
          <td align="center">{site["non_technical_contractor_name"]}</td>
          <td align="center">{site["cement_brand"]}</td>
          <td align="center">{site["city"]}</td>
          <td align="center">
            <Button
              className="mx-1"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  showFeedbackModal: true,
                  id: site.id,
                });
              }}
              style={{ backgroundColor: "transparent" }}
            >
              <FontAwesomeIcon
                icon={faCommentDots}
                style={{ color: "green" }}
              />
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "transparent" }}
              onClick={(e) => {
                this.setState({
                  showUpdateModal: true,
                  id: site.id,
                  owner_name: site.owner_name,
                  owner_mobile: site.owner_mobile,
                  engineer_name: site.engineer_name,
                  engineer_mobile: site.engineer_mobile,
                  architect_name: site.architect_name,
                  architect_mobile: site.architect_mobile,
                  non_technical_contractor_name:
                    site.non_technical_contractor_name,
                  non_technical_contractor_mobile:
                    site.non_technical_contractor_mobile,
                  centring_mistry_name: site.centring_mistry_name,
                  centring_mistry_mobile: site.centring_mistry_mobile,
                  bandhkaam_mistry_name: site.bandhkaam_mistry_name,
                  bandhkaam_mistry_mobile: site.bandhkaam_mistry_mobile,
                  authority: site.authority,
                  type: site.type,
                  stage: site.stage,
                  steel_brand: site.steel_brand,
                  cement_brand: site.cement_brand,
                  measurement: site.measurement,
                  last_modified: site.last_modified,
                  is_converted: site.is_converted,
                  locality: site.locality,
                  state: site.state,
                  city: site.city,
                  application_user_id: site.application_user_id,
                });
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} style={{ color: "blue" }} />
            </Button>
            <Button
              className="mx-1"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(site["id"]);
                }
              }}
              style={{ backgroundColor: "transparent" }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  showFeedbackModal() {
    return (
      <Modal
        show={this.state.showFeedbackModal}
        onHide={(e) => this.setState({ showFeedbackModal: false, id: "" })}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Feedbacks
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Feedback
            key={Date.now()}
            refresh={() => this.setState({ rerender: this.state.rerender + 1 })}
            site_id={this.state.id}
            toast={this.props.toast}
          />
        </Modal.Body>
      </Modal>
    );
  }

  renderUpdatePartyModal() {
    return (
      <Modal
        show={this.state.showUpdateModal}
        onHide={(e) => this.setState({ showUpdateModal: false })}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Site
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-2">
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Id"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.id}
                    style={{ width: "100%" }}
                    disabled
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Application User Id"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.application_user_id}
                    style={{ width: "100%" }}
                    disabled
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Last Modified"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.last_modified}
                    style={{ width: "100%" }}
                    disabled
                  />
                </Col>
                <Col>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={this.state.is_converted === 1 ? true : false}
                        onChange={(e) =>
                          this.setState({
                            is_converted: e.target.checked ? 1 : 0,
                          })
                        }
                      />
                    }
                    label="Converted"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Owner Name"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.owner_name}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        owner_name: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Owner Mobile"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.owner_mobile}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        owner_mobile: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Engineer Name"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.engineer_name}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        engineer_name: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Engineer Mobile"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.engineer_mobile}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        engineer_mobile: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Architect Name"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.architect_name}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        architect_name: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Architect Mobile1111"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.architect_mobile}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        architect_mobile: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Non Technical Contractor Name"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.non_technical_contractor_name}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        non_technical_contractor_name: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Non Technical Contractor Mobile"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.non_technical_contractor_mobile}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        non_technical_contractor_mobile: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Centring Mistry Name"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.centring_mistry_name}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        centring_mistry_name: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Centring Mistry Mobile"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.centring_mistry_mobile}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        centring_mistry_mobile: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Bandhkam Mistry Name"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.bandhkaam_mistry_name}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        bandhkaam_mistry_name: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Bandhkam Mistry Mobile"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.bandhkaam_mistry_mobile}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        bandhkaam_mistry_mobile: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Cement Brand"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.cement_brand}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        cement_brand: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Steel Brand"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={this.state.steel_brand}
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        steel_brand: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Measurement"
                    variant="outlined"
                    className="mb-3"
                    value={this.state.measurement}
                    style={{ width: "100%" }}
                    disabled
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Measurement(width)"
                    variant="outlined"
                    className="mb-3"
                    value={this.state.measurement.split("-")[1].split("x")[0]}
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      let t = e.target.value ? parseFloat(e.target.value) : 0;
                      let tmp = this.state.measurement.split("-");
                      let d = tmp[1].split("x");
                      tmp[0] = (t * parseFloat(d[1])).toString() + "sq.ft.";
                      this.setState({
                        measurement: tmp[0] + "-" + t.toString() + "x" + d[1],
                      });
                    }}
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Measurement(breadth)"
                    variant="outlined"
                    className="mb-3"
                    defaultValue={
                      this.state.measurement.split("-")[1].split("x")[1]
                    }
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      let t = e.target.value ? parseFloat(e.target.value) : 0;
                      let tmp = this.state.measurement.split("-");
                      let d = tmp[1].split("x");
                      tmp[0] = (t * parseFloat(d[0])).toString() + "sq.ft.";
                      this.setState({
                        measurement: tmp[0] + "-" + d[0] + "x" + t.toString(),
                      });
                    }}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Authority
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={this.state.authority}
                      onChange={(e) =>
                        this.setState({
                          authority: e.target.value,
                        })
                      }
                      label="Authority"
                    >
                      <MenuItem value="Owner">Owner</MenuItem>
                      <MenuItem value="Engineer">Engineer</MenuItem>
                      <MenuItem value="Contractor">Contractor</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={this.state.type}
                      onChange={(e) =>
                        this.setState({
                          type: e.target.value,
                        })
                      }
                      label="Type"
                    >
                      <MenuItem value="Residential">Residential</MenuItem>
                      <MenuItem value="Commertial">Commertial</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
                <Col>
                  <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-outlined-label">
                      stage
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={this.state.stage}
                      onChange={(e) =>
                        this.setState({
                          authority: e.target.value,
                        })
                      }
                      label="Authority"
                    >
                      <MenuItem value="footing">Footing</MenuItem>
                      <MenuItem value="plint level">Plint Level</MenuItem>
                      <MenuItem value="7th feet column">
                        7th Feet Column
                      </MenuItem>
                      <MenuItem value="7th feet brick work">
                        7th Feet Brick Work
                      </MenuItem>
                      <MenuItem value="slab level">Slab Level</MenuItem>
                      <MenuItem value="slab complete">Clab Complete</MenuItem>
                      <MenuItem value="pastering">Platering</MenuItem>
                      <MenuItem value="flooring">Flooring</MenuItem>
                    </Select>
                  </FormControl>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col>
                  <TextField
                    id="locality"
                    label="Locality"
                    variant="outlined"
                    className="mb-3"
                    defaultValue=""
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        locality: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="State"
                    label="State"
                    variant="outlined"
                    className="mb-3"
                    defaultValue=""
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        state: e.target.value,
                      })
                    }
                  />
                </Col>
                <Col>
                  <TextField
                    id="City"
                    label="City"
                    variant="outlined"
                    className="mb-3"
                    defaultValue=""
                    style={{ width: "100%" }}
                    onChange={(e) =>
                      this.setState({
                        city: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModal: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
              >
                Update
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <MyContainer path={["", "sites"]}>
        <>
          <div className="container-fluid border m-0 p-1">
            <div className="btn-group" role="group" aria-label="Basic example">
              <Button
                className="mt-1 mr-1 mb-3"
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  this.setState({
                    showAddModal: true,
                    authority: "Owner",
                    type: "Residential",
                    stage: "footing",
                    measurement: "0sq.ft.-0x0",
                  });
                }}
              >
                add new Site
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="mt-1 mr-1 mb-3 ml-5"
                onClick={(e) => window.location.reload(false)}
              >
                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
              </Button>
              {/* <Link to={`/test`}>
              <Button
                color="primary"
                variant="contained"
                className="mt-1 mr-1 mb-3 ml-5"
              >
                <FontAwesomeIcon icon={faFilter} />
              </Button>
              </Link> */}
            </div>
            <div>
              <Row>
                <Link to={`/test`}>
                  <Button
                    color="primary"
                    variant="contained"
                    className="mt-1 mr-4 mb-3 ml-3"
                    // onClick={() => {
                    //   this.FilterData();
                    // // }}
                    // value="search"
                  >
                    Filter Data Datewise
                  </Button>
                </Link>
              </Row>
            </div>

            {this.renderUpdatePartyModal()}
            {this.showFeedbackModal()}
            <Modal
              show={this.state.showAddModal}
              onHide={(e) => this.setState({ showAddModal: false })}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Add New Site
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form noValidate autoComplete="off">
                  <div className="mt-2">
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          label="Id"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          disabled
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="address"
                          label="Application User Id"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          disabled
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="address"
                          label="Last Modified"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          disabled
                        />
                      </Col>
                      <Col>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Converted
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.is_converted}
                            onChange={(e) =>
                              this.setState({
                                is_converted: e.target.value,
                              })
                            }
                          >
                            <MenuItem value="1">Converted</MenuItem>
                            <MenuItem value="2">Completed</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      {/* <Col>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={
                                this.state.is_converted === 1 ? true : false
                              }
                              onChange={(e) =>
                                this.setState({
                                  is_converted: e.target.checked ? 1 : 0,
                                })
                              }
                            />
                          }
                          label="Converted"
                        />
                      </Col> */}
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          label="Owner Name"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              owner_name: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="ownerMobile"
                          label="Owner Mobile"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              owner_mobile: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.state.engineerList != null
                              ? this.state.engineerList.map((item) => item.name)
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="engineerName"
                              label="Engineer Name"
                              variant="outlined"
                              className="mb-3"
                              value={this.state.engineer_name}
                              style={{ width: "100%" }}
                              onChange={(e) =>
                                this.setState({
                                  engineer_name: e.target.value,
                                })
                              }
                            />
                          )}
                          onChange={(event, value) => {
                            console.log(value);
                            console.log(this.state.engineerList);
                            if (value != null && value != "") {
                              this.setState({
                                engineer_name: value,
                                engineer_mobile: this.state.engineerList.find(
                                  (engineer) =>
                                    String(engineer.name).toLowerCase() ===
                                    String(value).toLowerCase()
                                )?.mobile,
                              });
                            } else {
                              this.setState({
                                engineer_name: "",
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="engineerMobile"
                          label="Engineer Mobile"
                          variant="outlined"
                          className="mb-3"
                          value={this.state.engineer_mobile || ""}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              engineer_mobile: e.target.value,
                            })
                          }
                          size="small"
                          disabled={!!this.state.engineer_mobile}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.state.architechList != null
                              ? this.state.architechList.map(
                                  (item) => item.name
                                )
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="architectName"
                              label="Architect Name"
                              variant="outlined"
                              className="mb-3"
                              Value={this.state.architect_name}
                              style={{ width: "100%" }}
                              onChange={(e) =>
                                this.setState({
                                  architect_name: e.target.value,
                                })
                              }
                            />
                          )}
                          onChange={(event, value) => {
                            console.log(value);
                            console.log(this.state.architechList);
                            if (value != null && value != "") {
                              this.setState({
                                architect_name: value,
                                architect_mobile: this.state.architechList.find(
                                  (architect) =>
                                    String(architect.name).toLowerCase() ===
                                    String(value).toLowerCase()
                                )?.mobile,
                              });
                            } else {
                              this.setState({
                                architect_name: "",
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="architechMobile"
                          label="Architect Mobile"
                          variant="outlined"
                          className="mb-3"
                          value={this.state.architect_mobile || ""}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              architect_mobile: e.target.value,
                            })
                          }
                          size="small"
                          disabled={!!this.state.architect_mobile}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {/* <TextField
                                                    id="address"
                                                    label="Non Technical Contractor Name"
                                                    variant="outlined"
                                                    className="mb-3"
                                                    defaultValue={
                                                        this.state
                                                            .non_technical_contractor_name
                                                    }
                                                    style={{ width: "100%" }}
                                                    onChange={(e) =>
                                                        this.setState({
                                                            non_technical_contractor_name:
                                                                e.target.value,
                                                        })
                                                    }
                                                /> */}
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.state.ntcList != null
                              ? this.state.ntcList.map((item) => item.name)
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Non Technical Contractor Name"
                              variant="outlined"
                              size="small"
                              value={this.state.newPartyName}
                              onChange={(event) =>
                                this.setState({
                                  newPartyName: event.target.value,
                                })
                              }
                            />
                          )}
                          onChange={(event, value) => {
                            console.log(value);
                            console.log(this.state.ntcList);
                            if (value != null && value != "") {
                              this.setState({
                                non_technical_contractor_name: value,
                                non_technical_contractor_mobile:
                                  this.state.ntcList.find(
                                    (Non_Technical_Contractor) =>
                                      String(
                                        Non_Technical_Contractor.name
                                      ).toLowerCase() ===
                                      String(value).toLowerCase()
                                  )?.mobile,
                              });
                              console.log(this.state);
                              console.log(
                                this.state.non_technical_contractor_name +
                                  "," +
                                  this.state.non_technical_contractor_mobile +
                                  " hi"
                              );
                            } else {
                              this.setState({
                                non_technical_contractor_name: "",
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="conMobile"
                          label="Non Technical Contractor Mobile"
                          variant="outlined"
                          className="mb-3"
                          value={
                            this.state.non_technical_contractor_mobile || ""
                          }
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              non_technical_contractor_mobile: e.target.value,
                            })
                          }
                          size="small"
                          disabled={
                            !!this.state.non_technical_contractor_mobile
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.state.centringmistryList != null
                              ? this.state.centringmistryList.map(
                                  (item) => item.name
                                )
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="centrilMistry"
                              label="Centring Mistry Name"
                              variant="outlined"
                              className="mb-3"
                              tValue={this.state.centring_mistry_name}
                              style={{ width: "100%" }}
                              onChange={(e) =>
                                this.setState({
                                  centring_mistry_name: e.target.value,
                                })
                              }
                            />
                          )}
                          onChange={(event, value) => {
                            console.log(value);
                            console.log(this.state.centringmistryList);
                            if (value != null && value != "") {
                              this.setState({
                                centric_mistry_name: value,
                                centric_mistry_mobile:
                                  this.state.centringmistryList.find(
                                    (centric_mistry) =>
                                      String(
                                        centric_mistry.name
                                      ).toLowerCase() ===
                                      String(value).toLowerCase()
                                  )?.mobile,
                              });
                            } else {
                              this.setState({
                                centric_mistry_name: "",
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="centricMistryMobile"
                          label="Centring Mistry Mobile"
                          variant="outlined"
                          className="mb-3"
                          value={this.state.centring_mistry_mobile || ""}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              centring_mistry_mobile: e.target.value,
                            })
                          }
                          size="small"
                          disabled={!!this.state.centring_mistry_mobile}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Autocomplete
                          id="free-solo-demo"
                          freeSolo
                          options={
                            this.state.bandhkaammistryList != null
                              ? this.state.bandhkaammistryList.map(
                                  (item) => item.name
                                )
                              : []
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              id="bandhkaamMistry"
                              label="Bandhkaam Mistry Name"
                              variant="outlined"
                              className="mb-3"
                              value={this.state.bandhkaam_mistry_name}
                              style={{ width: "100%" }}
                              onChange={(e) =>
                                this.setState({
                                  bandhkaam_mistry_name: e.target.value,
                                })
                              }
                            />
                          )}
                          onChange={(event, value) => {
                            if (value != null && value != "") {
                              this.setState({
                                bandhkaam_mistry_name: value,
                                bandhkaam_mistry_mobile:
                                  this.state.bandhkaammistryList.find(
                                    (bandhkaam_mistry) =>
                                      String(
                                        bandhkaam_mistry.name
                                      ).toLowerCase() ===
                                      String(value).toLowerCase()
                                  )?.mobile,
                              });
                            } else {
                              this.setState({
                                bandhkaam_mistry_name: "",
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="bandhkaamMistryMobile"
                          label="Bandhkam Mistry Mobile"
                          variant="outlined"
                          className="mb-3"
                          value={this.state.bandhkaam_mistry_mobile || ""}
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              bandhkaam_mistry_mobile: e.target.value,
                            })
                          }
                          size="small"
                          disabled={!!this.bandhkaam_mistry_mobile}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          label="Cement Brand"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              cement_brand: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="address"
                          label="Steel Brand"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              steel_brand: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          label="Measurement"
                          variant="outlined"
                          className="mb-3"
                          value={this.state.measurement}
                          style={{ width: "100%" }}
                          disabled
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="address"
                          label="Measurement(width)"
                          variant="outlined"
                          className="mb-3"
                          value={
                            this.state.measurement.split("-")[1].split("x")[0]
                          }
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            let t = e.target.value
                              ? parseFloat(e.target.value)
                              : 0;
                            let tmp = this.state.measurement.split("-");
                            let d = tmp[1].split("x");
                            tmp[0] =
                              (t * parseFloat(d[1])).toString() + "sq.ft.";
                            this.setState({
                              measurement:
                                tmp[0] + "-" + t.toString() + "x" + d[1],
                            });
                          }}
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="address"
                          label="Measurement(breadth)"
                          variant="outlined"
                          className="mb-3"
                          value={
                            this.state.measurement.split("-")[1].split("x")[1]
                          }
                          style={{ width: "100%" }}
                          onChange={(e) => {
                            let t = e.target.value
                              ? parseFloat(e.target.value)
                              : 0;
                            let tmp = this.state.measurement.split("-");
                            let d = tmp[1].split("x");
                            tmp[0] =
                              (t * parseFloat(d[0])).toString() + "sq.ft.";
                            this.setState({
                              measurement:
                                tmp[0] + "-" + d[0] + "x" + t.toString(),
                            });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Authority
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.authority}
                            onChange={(e) =>
                              this.setState({
                                authority: e.target.value,
                              })
                            }
                            label="Authority"
                          >
                            <MenuItem value="Owner">Owner</MenuItem>
                            <MenuItem value="Engineer">Engineer</MenuItem>
                            <MenuItem value="Contractor">Contractor</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      <Col>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            En
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.en}
                            onChange={(e) =>
                              this.setState({
                                en: e.target.value,
                              })
                            }
                            label="Authority"
                          >
                            <MenuItem value="1">En With Material</MenuItem>
                            <MenuItem value="2">En Supervision</MenuItem>
                            <MenuItem value="3">En liber basis</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      <Col>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            Type
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.type}
                            onChange={(e) =>
                              this.setState({
                                type: e.target.value,
                              })
                            }
                            label="Type"
                          >
                            <MenuItem value="Residential">Residential</MenuItem>
                            <MenuItem value="Commertial">Commertial</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                      <Col>
                        <FormControl
                          variant="outlined"
                          style={{ width: "100%" }}
                        >
                          <InputLabel id="demo-simple-select-outlined-label">
                            stage
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            value={this.state.stage}
                            onChange={(e) =>
                              this.setState({
                                authority: e.target.value,
                              })
                            }
                            label="Authority"
                          >
                            <MenuItem value="footing">Footing</MenuItem>
                            <MenuItem value="plint level">Plint Level</MenuItem>
                            <MenuItem value="7th feet column">
                              7th Feet Column
                            </MenuItem>
                            <MenuItem value="7th feet brick work">
                              7th Feet Brick Work
                            </MenuItem>
                            <MenuItem value="slab level">Slab Level</MenuItem>
                            <MenuItem value="slab complete">
                              Clab Complete
                            </MenuItem>
                            <MenuItem value="pastering">Platering</MenuItem>
                            <MenuItem value="flooring">Flooring</MenuItem>
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <TextField
                          id="locality"
                          label="Locality"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              locality: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="State"
                          label="State"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              state: e.target.value,
                            })
                          }
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="City"
                          label="City"
                          variant="outlined"
                          className="mb-3"
                          defaultValue=""
                          style={{ width: "100%" }}
                          onChange={(e) =>
                            this.setState({
                              city: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="mt-2 mr-1">
                    <Btn1
                      style={{ float: "right" }}
                      onClick={(e) => {
                        this.setState({
                          showUpdateModal: false,
                        });
                        this.handleAddSubmit(e);
                      }}
                    >
                      Create Site
                    </Btn1>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            <Row className="ml-0 mr-0">
              <Col md="12" className="p-0 m-0 measure1">
                <div>
                  <table
                    id="user_table"
                    className="display"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Id</th>
                        <th>Is Converted</th>
                        <th>Owner name</th>
                        <th>Engineer name</th>
                        <th>Contractor name</th>
                        <th>Cement Brand</th>
                        <th>Location</th>
                        <th
                          style={{
                            width: "fit-content",
                          }}
                        >
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.renderVehiclesData()}</tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </>
      </MyContainer>
    );
  }
}
