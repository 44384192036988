import React, { Component } from "react";
import { Link } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";

// material UI imports
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faBook,
  faTrash,
  faSyncAlt,
  faCommentDots,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

import MyContainer from "../main_container";

// datatable setup
import jsZip from "jszip";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");

//API handling components
let API_URL = `https://csm.5techg.com/api/`;

export default class Completed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      addEdit: true,
      showUpdateModel: false,
      showFeedback: false,
      id: "",
      owner_name: "",
      owner_mobile: "",
      engineer_name: "",
      engineer_mobile: "",
      architect_name: "",
      architect_mobile: "",
      non_technical_contractor_name: "",
      non_technical_contractor_mobile: "",
      centring_mistry_name: "",
      centring_mistry_mobile: "",
      bandhkam_mistry_name: "",
      bandhkam_mistry_mobile: "",
      authority: "",
      type: "",
      stage: "",
      steel_brand: "",
      cement_brand: "",
      measurement: "0.0sq.ft.-0x0",
      last_modified: "",
      is_converted: "0",
      locality: "",
      state: "",
      city: "",
      application_user_id: "3",
      vehiclesData: null,
      resultProductData: [],
      rerender: 0,
      toDate: "",
      fromDate: "",
      en : 0,
    };
    this.tmp = null;
  }

  fetchPartiesData() {
    let url = API_URL;
    const query = `SELECT * FROM site where is_converted = 2;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("data: ", res.data);
        this.setState({ vehiclesData: res.data });
      })
      .catch((err) => {
        console.log("vehicle data error: ", err);
      });
  }


  handleUpdateSubmit(e) {
    e.preventDefault();
    let url = API_URL;
    const query = `UPDATE user SET login_id="${this.state.login_id}", password="${this.state.password}", type=${this.state.type} WHERE id=${this.state.id};`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        this.props.refresh();
        this.props.toast.success("User updated successfully");
      })
      .catch((err) => {
        console.log("error while updating party data", err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `INSERT INTO user(login_id, password, type) VALUES('${this.state.login_id}', '${this.state.password}', ${this.state.type})`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        this.props.refresh();
        this.props.toast.success("User added successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteRecord(id) {
    let url = API_URL;
    const query = `delete from user WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("deleted status data: ", res.data);
        this.props.refresh();
        this.props.toast.error("User deleted successfully");
      })
      .catch((err) => {
        console.log("record delete error: ", err);
      });
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  componentDidMount() {
    this.fetchPartiesData();
  }

  componentDidUpdate() {
    this.tmp = $("#user_table").DataTable({
      destroy: true,
      keys: true,
      order: this.tmp ? this.tmp.order() : [0, "asc"],
      buttons: [
        "copy",
        "csv",
        "excel",
        {
          extend: "pdf",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
        {
          extend: "print",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
      ],
    });
  }

  renderVehiclesData = () => {
    const sites = this.state.vehiclesData;

    if (sites == null) {
      return null;
    }

    return sites.map((site, index) => {
      return (
        <tr key={`site_row_${index}`}>
          <td align="center">
            <Badge variant="primary">{site["id"]}</Badge>{" "}
          </td>
          <td align="center">
            {site["is_converted"] === 1 ? (
              <i
                className="fa fa-check-circle"
                style={{ fontSize: 16, color: "green" }}
              />
            ) : (
              <i
                className="fa fa-times-circle"
                style={{ fontSize: 16, color: "red" }}
              />
            )}
          </td>
          <td align="center">{site["owner_name"]}</td>
          <td align="center">{site["engineer_name"]}</td>
          <td align="center">{site["non_technical_contractor_name"]}</td>
          <td align="center">{site["cement_brand"]}</td>
          <td align="center">{site["city"]}</td>
          <td align="center">
            <Button
              className="mx-1"
              variant="contained"
              onClick={(e) => {
                this.setState({
                  showFeedbackModal: true,
                  id: site.id,
                });
              }}
              style={{ backgroundColor: "transparent" }}
            >
              <FontAwesomeIcon
                icon={faCommentDots}
                style={{ color: "green" }}
              />
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: "transparent" }}
              onClick={(e) => {
                this.setState({
                  showUpdateModal: true,
                  id: site.id,
                  owner_name: site.owner_name,
                  owner_mobile: site.owner_mobile,
                  engineer_name: site.engineer_name,
                  engineer_mobile: site.engineer_mobile,
                  architect_name: site.architect_name,
                  architect_mobile: site.architect_mobile,
                  non_technical_contractor_name:
                    site.non_technical_contractor_name,
                  non_technical_contractor_mobile:
                    site.non_technical_contractor_mobile,
                  centring_mistry_name: site.centring_mistry_name,
                  centring_mistry_mobile: site.centring_mistry_mobile,
                  bandhkam_mistry_name: site.bandhkam_mistry_name,
                  bandhkam_mistry_mobile: site.bandhkam_mistry_mobile,
                  authority: site.authority,
                  type: site.type,
                  stage: site.stage,
                  steel_brand: site.steel_brand,
                  cement_brand: site.cement_brand,
                  measurement: site.measurement,
                  last_modified: site.last_modified,
                  is_converted: site.is_converted,
                  locality: site.locality,
                  state: site.state,
                  city: site.city,
                  application_user_id: site.application_user_id,
                });
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} style={{ color: "blue" }} />
            </Button>
            <Button
              className="mx-1"
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(site["id"]);
                }
              }}
              style={{ backgroundColor: "transparent" }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  renderUpdatePartyModal() {
    return (
      <Modal
        show={this.state.showUpdateModal}
        onHide={(e) => this.setState({ showUpdateModal: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3">
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Id"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.id}
                    disabled
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Type"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.type}
                    onChange={(e) =>
                      this.setState({
                        type: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <TextField
                    id="partyName"
                    label="User Id"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.login_id}
                    onChange={(e) => {
                      this.setState({
                        login_id: e.target.value,
                      });
                    }}
                  />
                </Col>
                <Col>
                  <TextField
                    id="mobile"
                    label="Password"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.password}
                    onChange={(e) =>
                      this.setState({
                        password: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModal: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
              >
                Update
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }
  render() {
    return (
      <MyContainer path={["", "Completed"]}>
        <>
          <div className="container-fluid border m-0 p-1">
            {/* {console.log("inside user")} */}
            {/* <div class="btn-group" role="group" aria-label="Basic example">
              <Button
                className="mt-1 mr-1 mb-3"
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  this.setState({ showAddModal: true });
                }}
              >
                add new User
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="mt-1 mr-1 mb-3 ml-5"
                onClick={(e) => window.location.reload(false)}
              >
                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
              </Button>
            </div> */}

            {/* {this.renderUpdatePartyModal()} */}
            {/* <Modal
              show={this.state.showAddModal}
              onHide={(e) => this.setState({ showAddModal: false })}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Add New User
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form noValidate autoComplete="off">
                  <div className="mt-3">
                    <Row>
                      <Col size="12">
                        <TextField
                          id="partyName"
                          label="User Id"
                          variant="outlined"
                          className="m-2"
                          defaultValue=""
                          onChange={(e) => {
                            this.setState({
                              login_id: e.target.value,
                            });
                          }}
                        />
                      </Col>
                      <Col>
                        <TextField
                          id="mobile"
                          label="Password"
                          variant="outlined"
                          className="m-2"
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({
                              password: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <TextField
                          id="address"
                          label="Type"
                          variant="outlined"
                          className="m-2"
                          defaultValue=""
                          onChange={(e) =>
                            this.setState({
                              Type: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="mt-2 mr-1">
                    <Btn1
                      style={{ float: "right" }}
                      onClick={(e) => {
                        this.setState({
                          showAddModal: false,
                        });
                        this.handleAddSubmit(e);
                      }}
                    >
                      Add
                    </Btn1>
                  </div>
                </form>
              </Modal.Body>
            </Modal> */}
            <Row className="ml-0 mr-0">
              <Col md="12" className="p-0 m-0 measure1">
                <div>
                  <table
                    id="user_table"
                    className="display"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Id</th>
                        <th>Is Converted</th>
                        <th>Owner name</th>
                        <th>Engineer name</th>
                        <th>Contractor name</th>
                        <th>Cement Brand</th>
                        <th>Location</th>
                        <th style={{ width: "fit-content" }}>Options</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderVehiclesData()}</tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </>
      </MyContainer>
    );
  }
}
