import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MyContainer from "../main_container";

// material UI imports
import {
  Button,
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Card,
  Grid,
  Paper,
} from "@material-ui/core";
import { Row, Col, Button as Btn1, Modal, Badge } from "react-bootstrap";

// font awasome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenAlt,
  faBook,
  faTrash,
  faSyncAlt,
  faCalculator,
  faSleigh,
} from "@fortawesome/free-solid-svg-icons";

// datatable setup
import jsZip from "jszip";
import { Sync } from "@material-ui/icons";
window.JSZip = jsZip;
var $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs4");
require("datatables.net-autofill-bs4");
require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis");
require("datatables.net-buttons/js/buttons.flash");
require("datatables.net-buttons/js/buttons.html5");
require("datatables.net-buttons/js/buttons.print");
require("datatables.net-responsive-bs4");
require("datatables.net-scroller-bs4");
require("datatables.net-select-bs4");
require("pdfmake");

// constants
const axios = require("axios");

//API handling components
let API_URL = `https://csm.5techg.com/api/`;
const today = new Date();

const dateFormat = () => {
  let y = today.getUTCFullYear();
  let m = today.getUTCMonth() + 1;
  m = m < 10 ? "0" + m.toString() : m.toString();
  let d = today.getUTCDate();
  d = d < 10 ? "0" + d.toString() : d.toString();
  return y + "-" + m + "-" + d;
};

const Calcualtor = ({ id, number }) => {
  const [date, setDate] = useState(dateFormat());
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0.0);
 
 

  const getData = async () => {
    let url = API_URL;
    const query = `select v1.*,v2.vehicle_number from vehicle_reading v1, vehicles v2 where (v1.date BETWEEN '${date} 00:00:00' AND '${date} 23:59:59') AND v1.vehicle_id=v2.id AND v2.id=${id}`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    await axios
      .post(url, data)
      .then((res) => {
        console.log("reading_data: ", res.data, res.data.length);
        let t = res.data
          .map((val) => val.end - val.start)
          .reduce((a, b) => a + b, 0.0)
          .toFixed(2);
        setData(res.data);
        setTotal(t);
      })
      .catch((err) => {
        console.log("vehicle data error: ", err);
      });
  };

  useEffect(() => {
    getData();
  }, [date]);
  
  return (
    <>
      {console.log(date, data)}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ padding: "1em" }}>
            <Grid style={{ paddingBottom: "0.5em" }} xs={12}>
              <Row>
                <Col>
                  <TextField
                    id="date"
                    label="Pick Date"
                    type="date"
                    value={date}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />
                </Col>
                <Col>
                  <h6 style={{ padding: "1em", color: "#FF8C00" }}>
                    <div>{`Total Running :  `}</div>
                    <div>{`${total} km`}</div>
                  </h6>
                </Col>
              </Row>
            </Grid>
          </Paper>
        </Grid>
        <Row></Row>
        {(data || []).map((item, index) => (
          <Grid item xs={12}>
            <Paper style={{ padding: "1em" }}>
              <Grid style={{ paddingBottom: "0.5em" }} xs={12}>
                <Row>
                  <Col>
                    <h5 style={{ float: "left" }}>
                      {"Trip " + (index + 1).toString()}
                    </h5>
                  </Col>
                  <Col>
                    <h5 style={{ float: "right", color: "#FF8C00" }}>
                      {(item.end - item.start).toString() + " km"}
                    </h5>
                  </Col>
                </Row>
              </Grid>
              <Grid xs={12}>
                <Row>
                  <Col>
                    <h6 style={{ float: "left" }}>
                      <div
                        style={{
                          float: "left",
                          color: "#1E90FF",
                          paddingRight: "1em",
                        }}
                      >
                        S:
                      </div>
                      {item.start}
                    </h6>
                  </Col>
                  <Col>
                    <h6 style={{ float: "left" }}>
                      <div
                        style={{
                          float: "left",
                          color: "#1E90FF",
                          paddingRight: "1em",
                        }}
                      >
                        E:
                      </div>
                      {item.end}
                    </h6>
                  </Col>
                </Row>
              </Grid>
              <Grid xs={12}>
                <h6>
                  <div
                    style={{
                      float: "left",
                      color: "#1E90FF",
                      paddingRight: "1em",
                    }}
                  >
                    Driver:
                  </div>
                  {item.driver}
                </h6>
              </Grid>
              <Grid xs={12}>
                <h6>
                  <div
                    style={{
                      float: "left",
                      color: "#1E90FF",
                      paddingRight: "1em",
                    }}
                  >
                    Description:
                  </div>
                  {item.description}
                </h6>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default class Vehicles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddModal: false,
      addEdit: true,
      showUpdateModel: false,
      showCalculateModal: false,
      readings: {},
      id: "",
      vehicle_number: "",
      vehiclesData: null,
    };
    this.tmp = null;
  }

  fetchPartiesData() {
    let url = API_URL;
    const query = `SELECT * FROM vehicles;`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("vehicle data: ", res.data);
        this.setState({ vehiclesData: res.data });
      })
      .catch((err) => {
        console.log("vehicle data error: ", err);
      });
  }

  handleUpdateSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `UPDATE vehicles SET vehicle_number='${this.state.vehicle_number}' WHERE id=${this.state.id};`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        this.props.refresh();
        this.props.toast.success("Vehicle details updated successfully");
      })
      .catch((err) => {
        console.log("error while updating party data", err);
      });
  }

  handleAddSubmit(e) {
    e.preventDefault();
    let url = API_URL;

    const query = `INSERT INTO vehicles(vehicle_number) VALUES('${this.state.vehicle_number}')`;
    let data = {
      crossDomain: true,
      crossOrigin: true,
      query: query,
    };
    axios
      .post(url, data)
      .then((res) => {
        this.props.refresh();
        this.props.toast.success("Vehicle details added successfully");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  deleteRecord(id) {
    let url = API_URL;
    const query = `delete from vehicles WHERE id=${id};`;
    let data = { crossDomain: true, crossOrigin: true, query: query };
    axios
      .post(url, data)
      .then((res) => {
        console.log("deleted status data: ", res.data);
        this.props.refresh();
        this.props.toast.error("Party deleted successfully");
      })
      .catch((err) => {
        console.log("record delete error: ", err);
      });
  }

  handleTabs = (event, newValue) => {
    this.setState({ value: newValue });
  };

  componentDidMount() {
    this.fetchPartiesData();
  }

  componentDidUpdate() {
    console.log("=====");
    this.tmp = $("#user_table").DataTable({
      destroy: true,
      keys: true,
      order: this.tmp ? this.tmp.order() : [0, "asc"],
      buttons: [
        "copy",
        "csv",
        "excel",
        {
          extend: "pdf",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
        {
          extend: "print",
          messageTop: "<h4 style='text-align:center'>Users List</h4>",
          download: "open",
        },
      ],
    });
  }

  renderVehiclesData = () => {
    console.log("==>>>", this.state.vehiclesData);
    if (this.state.vehiclesData == null) {
      return null;
    }
    return this.state.vehiclesData.map((vehicle) => {
      return (
        <tr>
          <td align="center">
            <Badge variant="primary">{vehicle["id"]}</Badge>{" "}
          </td>
          <td align="center">{vehicle["vehicle_number"]}</td>
          <td align="center">
            <Button
              className="mx-1"
              variant="contained"
              style={{ backgroundColor: "transparent" }}
              onClick={(e) => {
                this.setState({
                  showCalculateModal: true,
                  id: vehicle.id,
                  vehicle_number: vehicle.vehicle_number,
                });
              }}
            >
              <FontAwesomeIcon icon={faCalculator} style={{ color: "green" }} />
            </Button>
            <Button
              className="mx-1"
              variant="contained"
              style={{ backgroundColor: "transparent" }}
              onClick={(e) => {
                this.setState({
                  showUpdateModal: true,
                  id: vehicle.id,
                  vehicle_number: vehicle.vehicle_number,
                });
              }}
            >
              <FontAwesomeIcon icon={faPenAlt} style={{ color: "blue" }} />
            </Button>
            <Button
              className="mx-1"
              style={{ backgroundColor: "transparent" }}
              variant="contained"
              onClick={(e) => {
                if (window.confirm("Delete the item?")) {
                  this.deleteRecord(vehicle["id"]);
                }
              }}
            >
              <FontAwesomeIcon icon={faTrash} style={{ color: "red" }} />
            </Button>
          </td>
        </tr>
      );
    });
  };

  renderUpdatePartyModal() {
    return (
      <Modal
        show={this.state.showUpdateModal}
        onHide={(e) => this.setState({ showUpdateModal: false })}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Update Vehicle
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form noValidate autoComplete="off">
            <div className="mt-3">
              <Row>
                <Col>
                  <TextField
                    id="address"
                    label="Id"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.id}
                    disabled
                  />
                </Col>
                <Col>
                  <TextField
                    id="address"
                    label="Vehicle Number"
                    variant="outlined"
                    className="m-2"
                    defaultValue={this.state.vehicle_number}
                    onChange={(e) =>
                      this.setState({
                        vehicle_number: e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="mt-2 mr-1">
              <Btn1
                style={{ float: "right" }}
                onClick={(e) => {
                  this.setState({
                    showUpdateModal: false,
                  });
                  this.handleUpdateSubmit(e);
                }}
              >
                Update
              </Btn1>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    );
  }

  showCalculateModal() {
    return (
      <Modal
        show={this.state.showCalculateModal}
        style={{ paddingBottom: "1em" }}
        onHide={(e) =>
          this.setState({
            showCalculateModal: false,
            id: "",
            vehicle_number: "",
          })
        }
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Vehicle : {this.state.vehicle_number}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Calcualtor id={this.state.id} number={this.state.vehicle_number} />
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    console.log(this.state.vehiclesData);
    return (
      <MyContainer path={["", "vehicles"]}>
        <>
          <div className="container-fluid border m-0 p-1">
            <div class="btn-group" role="group" aria-label="Basic example">
              <Button
                className="mt-1 mr-1 mb-3"
                color="secondary"
                variant="contained"
                onClick={(e) => {
                  this.setState({ showAddModal: true });
                }}
              >
                add new Vehicle
              </Button>
              <Button
                color="primary"
                variant="contained"
                className="mt-1 mr-1 mb-3 ml-5"
                onClick={(e) => window.location.reload(false)}
              >
                <FontAwesomeIcon icon={faSyncAlt} size="2x" />
              </Button>
            </div>

            {this.renderUpdatePartyModal()}
            {this.showCalculateModal()}
            <Modal
              show={this.state.showAddModal}
              onHide={(e) => this.setState({ showAddModal: false })}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  Add New Vehicle
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form noValidate autoComplete="off">
                  <div className="mt-3">
                    <Row>
                      <Col size="12">
                        <TextField
                          id="partyName"
                          label="Vehicle Number"
                          variant="outlined"
                          className="m-2"
                          defaultValue=""
                          style={{ width: "95%" }}
                          onChange={(e) => {
                            this.setState({
                              vehicle_number: e.target.value,
                            });
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr />
                  <div className="mt-2 mr-1">
                    <Btn1
                      style={{ float: "right" }}
                      onClick={(e) => {
                        this.setState({
                          showAddModal: false,
                        });
                        this.handleAddSubmit(e);
                      }}
                    >
                      Add
                    </Btn1>
                  </div>
                </form>
              </Modal.Body>
            </Modal>
            <Row className="ml-0 mr-0">
              <Col md="12" className="p-0 m-0 measure1">
                <div>
                  <table
                    id="user_table"
                    className="display"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr align="center">
                        <th>Id</th>
                        <th>Vehicle Number</th>
                        <th>Options</th>
                      </tr>
                    </thead>
                    <tbody>{this.renderVehiclesData()}</tbody>
                  </table>
                </div>
              </Col>
            </Row>
          </div>
        </>
      </MyContainer>
    );
  }
}
